.view-job-container {
  width: 85%;
  border: 1px solid #ccc;
  padding: 20px;
  margin: 10px auto;
}

.company-icon-container {
  text-align: center;
  margin: 10px;
}

.title {
  text-align: center;
  font-size: 24px;
  margin-bottom: 20px;
  font-weight: normal;
}

.info {
  text-align: left;
  font-size: 18px;
  margin-bottom: 20px;
}

.job-description {
  margin-bottom: 20px;
}

.job-subtitle {
  font-weight: bold;
  font-size: 24px;
  margin-bottom: 10px;
}

.apply-buttons {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  margin-top: 10px;
}

.apply-column {
  display: flex;
  flex-direction: column;
}

.apply-button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 8px 16px;
  margin-bottom: 10px;
  cursor: pointer;
}

.back-button {
  background-color: #dc3545;
  color: #fff;
  border: none;
  padding: 8px 16px;
  cursor: pointer;
}

.error-message {
  color: #dc3545;
}

.success-message {
  color: #28a745;
}

.message-container {
  text-align: center;
}

input[type="file"] {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
}
