/* FunctionList.css */
.function-list-container {
  display: flex;
  justify-content: center;
}

.function-list {
  /* list-style-type: none; */
  padding: 10px;
}

.function-list li {
  margin-bottom: 5px;
}

.function-list-item {
  color: black; /* Set text color to black */
  text-decoration: none; /* Remove default underline */
}

.function-list-item:hover {
  text-decoration: underline; /* Underline when hovered */
  cursor: pointer; /* Show pointer cursor on hover */
}
