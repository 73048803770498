.solutions-container {
  text-align: center;
}

.fade-in {
  animation: fade-in 1s ease-out;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fade-in:hover {
  transform: scale(1.1);
}

/* Add styling for solutions here */
/* jj */