/* General Styling */
body {
  margin: 0;
   font-family: "Poppins", serif;
  line-height: 1.6;
}

.data-warehousing-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  box-sizing: border-box;
}

/* Hero Section */
.hero-section {
  text-align: center;
  padding: 50px 20px;
  /* background: #1976d2; */
  /* color: white; */
  border-radius: 10px;
}

.hero-section h1 {
  /* font-size: 2.5rem; */
  margin-bottom: 15px;
}

.hero-section p {
  /* font-size: 1.2rem; */
  color: black;
  margin-top: 100px;
  margin-bottom: 0;
}

@media (max-width: 768px) {
  .hero-section h1 {
    font-size: 2rem;
  }


}

/* Key Concepts Section */
.key-concepts {
  margin: 40px 0;
}

.key-concepts h2 {
  text-align: center;
  margin-bottom: 20px;
  color: black;
}

.concepts-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
}

.concept-item {
  background: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.concept-item h3 {
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 10px;
  
}

.concept-item p {
  font-size: 18px;
}

/* Benefits Section */
.benefits {
  margin: 40px 0;
}

.benefits h2 {
  text-align: center;
  margin-bottom: 20px;
}

.benefits ul {
  list-style-type: none;
  padding: 0;
}

.benefits li {
  margin: 10px 0;
  font-size: 1rem;
  line-height: 1.5;
}



.cta-section h2 {
  font-size: 1.8rem;
  margin-bottom: 15px;
}

.cta-section p {
  /* font-size: 1.2rem; */
  margin-bottom: 20px;
}





