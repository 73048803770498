.introduction-container {
  padding: 20px;
  background-color: #f7f7f7;
  border-radius: 10px;
}

.introduction-container h1 {
  color: #333;
}

.introduction-text {
  margin-bottom: 20px;
}

.api-list {
  list-style-type: none;
  padding-left: 20px;
}

.api-command {
  font-weight: bold;
}

.api-example {
  font-family: monospace;
  background-color: #eee;
  padding: 5px;
  border-radius: 5px;
}

.api-description {
  margin-bottom: 10px;
}
