/* *{
    margin: 0;
    box-sizing: border-box;
    padding: 0;
}

.submit-inquiry-container {
    
    margin: 10px auto;
    width: 500px;
    max-width: 100%;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 20px;
}

.submit-inquiry-container h2 {
    margin-bottom: 20px;
}

.submit-inquiry-container form {
    display: flex;
    flex-direction: column;
}

.submit-inquiry-container input {
    margin-bottom: 10px;
    padding: 8px;
    
}

.submit-inquiry-container textarea {
    margin-bottom: 10px;
    padding: 8px;
    max-width: 90%;
    height: 150px;
}

.submit-inquiry-container button {
    padding: 8px 16px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.submit-inquiry-container button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.submit-inquiry-container p {
    margin-top: 10px;
    font-size: 14px;
    color: #888;
}



 

 
.submit-inquiry-container {
    max-width: 500px;
  
    margin: 0;
    margin-inline: auto;
    padding: 30px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
   
}


.submit-inquiry-container h2 {
    text-align: center;
    margin-bottom: 30px;
    font-size: 28px;
    color: #333;
    font-weight: 600;
}


.submit-inquiry-container form {
    
    
    width:100% ;
    
    gap: 20px;  
}


.submit-inquiry-container input

{
    width: 100%;
    
    padding: 14px;
    border: 1px solid #ccc;
    
    border-radius: 5px;
    font-size: 16px;
    color: #333;
    transition: all 0.3s ease;
}


.submit-inquiry-container input:focus,
.submit-inquiry-container textarea:focus {
    border-color: #007bff;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.3);
    outline: none;
}


.submit-inquiry-container textarea {
    height: 180px;  
    resize: vertical; 
     width: 100%;
    padding: 14px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    color: #333;
    transition: all 0.3s ease;
}


.submit-inquiry-container button {
    padding: 14px 20px;
    background-color: #007bff;
    color: #fff;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
}


.submit-inquiry-container button:hover {
    background-color: #0056b3;
    transform: translateY(-2px);  
}


.submit-inquiry-container button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
}


.submit-inquiry-container p {
    text-align: center;
    margin-top: 20px;
    font-size: 16px;
    color: #28a745;
    font-weight: 500;
}


.submit-inquiry-container p.error {
    color: #dc3545;  
}


@media (max-width: 600px) {
    .submit-inquiry-container {
        padding: 20px;
        margin: 10px;
        
    }

    .submit-inquiry-container h2 {
        font-size: 22px;
    }

    .submit-inquiry-container button {
        font-size: 14px;
    }
} */

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Arial', sans-serif;
}

/* Main container */
.submit-inquiry-container {
    max-width: 90%;
    width: 100%;
    max-width: 600px;
    margin: 40px auto;
    padding: 30px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease-in-out;
}

/* Heading */
.submit-inquiry-container h2 {
    text-align: center;
    font-size: 26px;
    color: #333;
    font-weight: 600;
    margin-bottom: 10px;
}

/* Subtext */
.submit-inquiry-container p {
    text-align: center;
    font-size: 16px;
    color: #666;
    margin-bottom: 20px;
}

/* Form layout */
.submit-inquiry-container form {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

/* Form groups */
.form-group {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
}

/* Form fields */
.form-row {
    display: flex;
    flex-direction: column;
    flex: 1;
    min-width: 250px;
}

/* Labels */
.form-row label {
    font-size: 14px;
    color: #555;
    font-weight: 500;
    margin-bottom: 5px;
}

/* Inputs & Textarea */
.form-row input,
.form-row textarea {
    width: 100%;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 0px !important;
    font-size: 16px;
    color: #333;
    transition: all 0.3s ease;
    background-color: #f9f9f9;
}

/* Hover & Focus Effect */
.form-row input:hover,
.form-row textarea:hover {
    background-color: #f1f1f1;
}

.form-row input:focus,
.form-row textarea:focus {
    border-color: #007bff;
    box-shadow: 0 0 8px rgba(0, 123, 255, 0.3);
    outline: none;
}

/* Textarea */
.form-row textarea {
    height: 150px;
    resize: vertical;
}

/* Submit button */
.submit-inquiry-container button {
    width: 100%;
    padding: 14px;
    background-color: #007bff;
    color: #fff;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease, box-shadow 0.2s ease;
    font-weight: 600;
}

/* Hover and click effects */
.submit-inquiry-container button:hover {
    background-color: #0056b3;
    transform: translateY(-2px);
    box-shadow: 0 4px 10px rgba(0, 91, 187, 0.2);
}

.submit-inquiry-container button:active {
    transform: scale(0.98);
}

/* Disabled button */
.submit-inquiry-container button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
    box-shadow: none;
}

/* Response message */
.submit-inquiry-container .success,
.submit-inquiry-container .error {
    text-align: center;
    font-weight: 500;
    margin-top: 10px;
    padding: 10px;
    border-radius: 5px;
    transition: all 0.3s ease-in-out;
}

.submit-inquiry-container .success {
    color: #155724;
    background-color: rgba(40, 167, 69, 0.1);
}

.submit-inquiry-container .error {
    color: #721c24;
    background-color: rgba(220, 53, 69, 0.1);
}

/* Mobile responsiveness */
@media (max-width: 768px) {
    .submit-inquiry-container {
        padding: 20px;
        margin: 20px auto;
    }

    .submit-inquiry-container h2 {
        font-size: 22px;
    }

    .submit-inquiry-container button {
        font-size: 14px;
        padding: 12px;
    }

    .form-group {
        flex-direction: column;
    }
}

@media (max-width: 480px) {
    .submit-inquiry-container {
        padding: 15px;
    }

    .form-row {
        min-width: 100%;
    }

    .form-row input,
    .form-row textarea {
        padding: 10px;
        font-size: 14px;
    }

    .submit-inquiry-container button {
        font-size: 14px;
        padding: 10px;
    }
}
