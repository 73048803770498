.system-con{
    display: flex;
    /* flex-wrap: wrap; */
    /* border: 2px solid red; */
    /* background-color: #ffff; */
    gap: 3rem;
    margin-block: 100px ;
    padding-inline: 30px;
    padding-block: 30px;
    justify-content: center;
    font-family: "Poppins", serif;
    /* background-color: white; */
}


.sys-image img{
    width: 750px;
    height: auto;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2); /* Adjust values as needed */
  }

.control h1 {
    text-align: center;
    font-family: "Poppins", serif;
}

.sys-text{
    /* background-color: white; */
    font-size: 20px;
    width: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;

}
.sys-text p{
    margin-bottom: 40px;
}


@media screen  and (max-width:950px){
    .sys-text{
        width: 100%;
    }
   .sys-text p{
    text-align: center;

   }
   .system-con{
    display: flex;
    flex-direction: column;
   }
}
