.offering_div {
  /* background-color: #fff; */
  height: fit-content;

  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
}
.offering_div h1 {
  font-family: "Poppins", serif;
}
.offering_container {
  margin-top: 0px;
  text-align: center;
  width: 100%;
  font-family: "Poppins", serif;
  
}

.offering_title {
  text-align: center;
  font-size: 50px;
  font-family: "Poppins", serif;
  /* margin: 30px; */
}

.offering_button {
  margin: 20px auto;
  width: 60%;
  font-family: "Poppins", serif;
}

.offering_button button {
  margin: 20px auto;
  width: 60%;
  background-color: green;
}

.featured .box {
  box-shadow: 0 0 20px 0 rgb(112 121 138 / 18%);
  border-radius: 6px;
  text-align: center;
  padding: 30px;
  cursor: pointer;
}

.featured img {
  width: 65px;
  height: 65px;
  margin: auto;
}

.contact-button {
  margin: 15px;
  width: 40%;
  background-color: green;
}

@keyframes scaleAnimation {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(1.2); /* Set the desired scale factor */
    }
  }

.contact-button:hover {
  animation: scaleAnimation 0.1s ease forwards;
}

@media screen and (max-width:900px){
   .offering_title{
    font-size: 30px;
   }
}