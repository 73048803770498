.api-docs-container {
  display: flex;
  overflow: hidden; /* To contain right side overflow */
}

.contents-bar {
  flex: 0 0 20%;
  background-color: #f4f4f4;
  padding: 20px;
}

.contents-bar ul {
  list-style: none;
  padding: 0;
}

.contents-bar li {
  padding: 10px;
}

.contents-bar li.selected {
  background-color: #ddd;
}

.main-content {
  flex: 1;
  padding: 20px;
  overflow: scroll;
}
