.affiliations-backdrop {
  /* height: 1000px; */
  display: flex;
  /* justify-content: center; */
  /* align-items: center; */
  height: 100vh;
 font-family: "Poppins", serif;

  /* background-image: url("../../../../../public/uni.gif"); */
  /* background-color: #fff; */
  /* background-color: #009999; */
  color: #006666;
  padding-bottom: 0px;
}

.affiliations-container p {
  font-size: 60px;
  max-height: 100%;
  margin: 40px;
 font-family: "Poppins", serif;
}

.rolling-counter {
  /* Add styles for the rolling counter */
  margin: auto;
  padding: 0px;
}

.logos {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  max-width: 80%;
  overflow: hidden;
}

.logos img {
  max-width: 100px;
  margin: 0 10px;
  transition: opacity 0.5s ease;
  border-radius: 10px;
}

/* jhhhlhjl */