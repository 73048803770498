/* Search Container */
.search-container {
  display: flex;
  align-items: center;
  padding: 6px;
  width: 100%;
  transition: all 0.3s ease-in-out;
}


/* Search Input */
.search-input {
  flex-grow: 1;
  border: none;
  background: transparent;
  font-size: 16px;
  padding: 12px 18px;
  border-radius: 50px;
  outline: none;
  color: #333;
  transition: all 0.3s ease-in-out;
}

.search-input::placeholder {
  color: rgba(0, 0, 0, 0.4);
}

.search-input:focus {
  background: #d3f9e6;
  box-shadow: 0px 0px 10px rgba(0, 153, 112, 0.3);
}

/* Search Button */
.search-button {
  width: 42px;
  height: 42px;
  background: #009970;
  border: none;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.search-button:hover {
  background: #007a5f;
  transform: scale(1.1);
}

.search-button svg {
  color: white;
  transition: all 0.3s ease-in-out;
}

.search-button:hover svg {
  transform: rotate(10deg);
}

/* Responsive Design */
@media (max-width: 768px) {
  .search-container {
    max-width: 100%;
    padding: 5px;
  }

  .search-input {
    font-size: 14px;
    padding: 10px 15px;
  }

  .search-button {
    width: 38px;
    height: 38px;
  }
}
