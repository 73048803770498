*{
  margin: 0;
  box-sizing: border-box;
  padding: 0;
}


.services_container {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  justify-content: center;
  height: fit-content;
  padding-bottom: 70px;
  padding-inline: 20px;
  /* border: 2px solid red; */
  /* flex-direction: column; */
  /* grid-gap: 10px;   */
  /* Simply adjust this value !*/
  /* grid-template-columns: repeat(3, 1fr); */
   /* OR grid-template-columns: 1fr 1fr 1fr;*/
  /* grid-auto-rows: 1fr; */
  /* border:1px solid green */
}
.service-item p{
  font-size: 20px;
  margin-block: 20px;
}
.service-item {
  /* border: 1px solid grey; */
  border-radius: 5px;
  /* height: 40px; */
  display: flex;
  flex-direction: column;
  background-color: white;
  height: fit-content;
  padding: 15px;
  width:80%;
  margin: auto;
  margin-bottom: 40px;
  /* margin: auto; */
}
 
.service-item:hover{
  background: grey;
}

.image-con{
  height: 800px;
  width: 700px;
  background-position: center;
  background-size: cover;
  background-image: url("/public/hbnew.jpg");
}


.serv{
  display: flex;
  flex-direction:column ;
}
@media screen and (max-width:900px)  {
  .services_container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: fit-content;
  }
  .service-item{
    width: 100%;
  }

  .image-con{
    width: 300px;
    height: 400px;
    margin-inline: auto;
    margin-bottom: 30px;
    
  }
  .image-inner {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .services_container h1{
    font-size: 30px;
  }

  .service-item p{
  font-size: 18px;
  margin-block: 0px;
}

.ser-con{
  display: flex;
  flex-direction: column;
}
}