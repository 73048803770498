/* General Styles */
body {
  font-family: "Poppins", sans-serif;
  margin: 0;
  padding: 0;
  line-height: 1.6;
  color: #2d3748; /* Darker Gray for better readability */
  background-color: #f4f7fc; /* Light pastel blue instead of plain white */
}

/* Accordion container */
.accordion {
  padding: 30px;
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  margin: 30px auto;
}

/* Header */
.header2 {
  text-align: center;
  font-size: 28px;
  font-weight: bold;
  color: #4a90e2; /* Softer blue for a modern look */
  margin-bottom: 10px;
}

/* Flex container */
.conflex {
  display: flex;
  flex-wrap: wrap;
  gap: 20px; /* Increased gap for better separation */
  justify-content: center; /* Center items for a balanced look */
  align-items: stretch; /* Make items the same height */
  padding: 20px; /* Added padding for spacing */
}


/* Inquiry & FAQ Sections */
.s, .accord {
  flex: 1;
  max-width: 48%;
  background: #dde6f5; 
  padding: 25px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

/* FAQ Titles */
.ach3 {
  font-size: 24px;
  color: #2c3e50; /* Darker text for contrast */
  text-align: center;
  margin-bottom: 15px;
}

.accord h4 {
  font-size: 20px;
  margin-bottom: 10px;
  color: #4a90e2; /* Soft blue for headings */
  font-weight: bold;
  text-align: center;
}

.accord p {
  font-size: 16px;
  color: #2d3748;
  text-align: center;
}

/* Accordion Item */
.accordion-item {
  background: #f7faff; /* Light pastel blue */
  border-radius: 8px;
  padding: 12px 15px;
  margin-bottom: 12px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in-out;
}

/* Accordion Header */
.accordion-header {
  background: none;
  border: none;
  outline: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
  padding: 12px;
  border-radius: 6px;
  transition: background 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.accordion-header:hover {
  background-color: #ebf4ff; /* Soft hover effect */
  box-shadow: 0 3px 8px rgba(74, 144, 226, 0.2);
}

.accordion-header.active {
  background-color: #cde1ff; /* Active state */
}

/* Arrow animation */
.accordion-arrow {
  font-size: 18px;
  transition: transform 0.3s ease-in-out;
}

.accordion-arrow.open {
  transform: rotate(180deg);
}

/* Accordion Content */
.accordion-content {
  max-height: 0;
  overflow: hidden;
  opacity: 0;
  transition: max-height 0.3s ease-in-out, opacity 0.3s ease-in-out;
  padding: 0 12px;
  color: #2d3748;
  font-size: 16px;
  line-height: 1.5;
}

.accordion-content.visible {
  max-height: 200px;
  opacity: 1;
  padding: 12px;
}

/* Submit Inquiry Form */
.submit-inquiry-container {
  max-width: 100%;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
}

.submit-inquiry-container h2 {
  text-align: center;
  font-size: 26px;
  color: #2c3e50;
  font-weight: 600;
  margin-bottom: 10px;
}

.submit-inquiry-container p {
  text-align: center;
  font-size: 16px;
  color: #666;
  margin-bottom: 20px;
}

.submit-inquiry-container form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

/* Form Input Styles */
.form-row input,
.form-row textarea {
  width: 100%;
  padding: 12px;
  border: 1px solid #4a90e2;
  border-radius: 6px;
  font-size: 16px;
  color: #2c3e50;
  background-color: #f7faff;
  transition: all 0.3s ease;
}

.form-row input:focus,
.form-row textarea:focus {
  border-color: #4a90e2;
  box-shadow: 0 0 10px rgba(74, 144, 226, 0.4);
  outline: none;
}

.form-row textarea {
  height: 150px;
  resize: vertical;
}

/* Submit button */
.submit-inquiry-container button {
  width: 100%;
  padding: 14px;
  background: linear-gradient(135deg, #4a90e2, #357ab7);
  color: #fff;
  font-size: 16px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: background 0.3s ease, transform 0.2s ease, box-shadow 0.2s ease;
  font-weight: 600;
}

.submit-inquiry-container button:hover {
  background: linear-gradient(135deg, #357ab7, #1c5f99);
  transform: translateY(-2px);
  box-shadow: 0 4px 10px rgba(53, 122, 183, 0.4);
}

.submit-inquiry-container button:active {
  transform: scale(0.98);
}

/* Response messages */
.submit-inquiry-container .success,
.submit-inquiry-container .error {
  text-align: center;
  font-weight: 500;
  margin-top: 10px;
  padding: 10px;
  border-radius: 6px;
  transition: all 0.3s ease-in-out;
}

.submit-inquiry-container .success {
  color: #2c7a2c;
  background-color: rgba(72, 187, 120, 0.2);
}

.submit-inquiry-container .error {
  color: #b22222;
  background-color: rgba(220, 53, 69, 0.2);
}

/* Responsive Design */
@media screen and (max-width: 900px) {
  .conflex {
    flex-direction: column-reverse;
  }

  .s, .accord {
    max-width: 100%;
  }

  .accordion {
    padding: 20px;
  }

  .header2 {
    font-size: 24px;
  }
  .accordion-question{
    font-size:0.9rem ;
  }
}

@media screen and (max-width: 480px) {
  .accordion {
    padding: 15px;
  }

  .header2 {
    font-size: 22px;
  }

  .accord h4 {
    font-size: 18px;
  }

  .accordion-header {
    font-size: 16px;
  }

  .accordion-content {
    font-size: 14px;
  }

  .s,.accord{
    padding: 15px;
  }

  .accordion-question{
    font-size:0.8rem ;
  }
  .accordion-item{
    padding-inline: 5px;
  }
}
