/* Container Styles */
.request-registration-container {
  display: flex;
  height: 100vh; /* Full viewport height */
  font-family: 'Poppins', sans-serif; /* Modern font */
  background: linear-gradient(135deg, #eef2f3, #d9e2ec); /* Subtle gradient */
  overflow: hidden; /* Prevent scrolling */
}

/* Left Image Section */
.image-half {
  flex: 1.5; /* Proportional space for the image */
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(135deg, #c9d6df, #eef2f3);
}

.registration-image {
  max-width: 75%;
  max-height: 75%;
  object-fit: contain;
  border-radius: 15px;
  box-shadow: 0px 12px 40px rgba(0, 0, 0, 0.15); /* Softer shadow */
  animation: fadeIn 1s ease-in-out;
}

/* Right Form Section */
.form-half {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background: #ffffff;
  box-shadow: -8px 0px 20px rgba(0, 0, 0, 0.1);
}

/* Form Content */
.form-content {
  width: 100%;
  max-width: 360px; /* Optimized width for various screens */
  height: 90%; /* Prevent overflow */
  padding: 20px;
  border-radius: 20px;
  background: white;
  box-shadow: 0px 8px 30px rgba(0, 0, 0, 0.1); /* Elevated shadow for depth */
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Distribute space evenly */
}

h2 {
  font-size: 24px;
  font-weight: bold;
  color: #3d4a5d;
  margin-bottom: 10px;
  text-align: center;
}

form {
  display: flex;
  flex-direction: column;
  gap: 10px; /* Balanced spacing */
}

/* Form Inputs */
.input-field, .half-width-input {
  padding: 10px 15px;
  border: 2px solid #e5e7eb;
  border-radius: 10px;
  background: #f9fbfc;
  font-size: 14px;
  color: #3d4a5d;
  transition: all 0.3s;
}

.input-field:focus, .half-width-input:focus {
  border-color: #5b8dee;
  background: #fff;
  box-shadow: 0px 4px 10px rgba(91, 141, 238, 0.2); /* Glow on focus */
}

.row {
  display: flex;
  justify-content: space-between;
  gap: 8px;
}

.half-width-input {
  width: 48%;
}



/* Resend Token Text */
.resend-token-text {
  font-size: 14px;
  color: #5b8dee;
  text-decoration: underline;
  cursor: pointer;
  margin-top: 10px;
  transition: color 0.2s;
  text-align: center;
}

.resend-token-text:hover {
  color: #396ddf;
}

/* Modal */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  animation: fadeIn 0.5s ease-in-out;
  z-index: 1000;
}

.modal-content {
  width: 90%;
  max-width: 400px;
  padding: 20px;
  border-radius: 20px;
  background: white;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.2);
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 15px; /* Proper spacing between elements */
}

.modal-content input {
  width: 100%;
  padding: 12px;
  border: 2px solid #e5e7eb;
  border-radius: 10px;
  background: #f9fbfc;
  font-size: 14px;
  transition: all 0.3s ease;
}

.modal-content input:focus {
  border-color: #5b8dee;
  box-shadow: 0px 4px 8px rgba(91, 141, 238, 0.2);
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Responsive Design */
@media (max-width: 768px) {
  .request-registration-container {
    flex-direction: column;
  }

  .image-half {
    height: 40%;
    flex: none;
  }

  .form-half {
    height: 60%;
    flex: none;
    padding: 15px;
  }

  .form-content {
    height: 100%; /* Fill available space */
  }
}

@media (max-width: 480px) {
  h2 {
    font-size: 20px;
  }

  .input-field, .half-width-input, button {
    font-size: 12px;
    padding: 10px;
  }

  .resend-token-text {
    font-size: 12px;
  }
}
