.info-section {
    padding: 2rem;
    /* background-color: #f9f9f9; */
  }

  .info-container {
    display: flex;
    /* flex-wrap: wrap; */
    /* flex-direction: column; */
    /* border: 2px solid red; */
    gap: 1rem;
    max-width: 1200px;
    margin: 0 auto;
    font-family:  "Poppins", serif;;
  }

  .info-box {
    background: #ffffff;
    width: 500px;
    padding: 1.5rem;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  .info-box h2 {
    font-size: 1.2rem;
    margin-bottom: 1rem;
     font-family: "Poppins", serif;
  }

  .info-box p {
    font-size: 18px;
    padding-block: 30px;
    line-height: 1.6;
    margin-bottom: 1rem;
  }

  .info-box ul {
    padding-left: 1.5rem;
    margin-bottom: 1rem;
  }

  .info-box ul li {
    list-style: disc;
    margin-bottom: 0.5rem;
  }

  .learn-more {
    color: #007bff;
    text-decoration: none;
    font-weight: bold;
  }

  .learn-more:hover {
    text-decoration: underline;
  }

  .info-section h1 {
    text-align: center;
     font-family: "Poppins", serif;
  }