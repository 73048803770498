.video-container {
  display: flex; 
  /* margin-inline: auto; */
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 20px; /* Add some spacing around the video */
  box-sizing: border-box;

}

.video-container iframe {
  width: 100%; /* Adjust the width (e.g., 80% of the parent) */
  max-width: 900px; /* Add a maximum width for larger screens */
  /* height: calc(80% * 9 / 16);  */
  height: 400px;
  border: none;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Optional: Add a shadow for style */
  border-radius: 8px; /* Optional: Add rounded corners */
  margin-bottom: 40px;
}


@media screen and (max-width:750px){
    .video-container iframe{
         height: 300px;
    }
}