
/* Container styling */
.testimonial-slider {
  position: relative;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  overflow: hidden;
  background-color:white;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  font-family: "Poppins", serif;
}

.test-con{
  margin-bottom: 120px;
}
.test-con h1{
  font-size: 50px;
  margin-bottom: 130px;
  text-align: center;
  font-family: "Poppins", serif;
}

/* .test-con h2 {
  color: #007BFF;
  margin-top: 120px;
} */

/* Slide container */
.testimonial-slide {
  display: flex;
  position: relative;
  animation: slider 12s infinite; /* 4 testimonials, each visible for 3 seconds */
}

/* Individual testimonial item */
.testimonial-item {
  flex: 0 0 100%;
  padding: 20px;
  box-sizing: border-box;
  text-align: center;
}

.testimonial-message {
  font-size: 20px;
  /* font-style: italic; */

  color: #555;
}

.testimonial-author {
  font-size: 16px;
  font-weight: bold;
  margin-top: 10px;
  color: #333;
}


/* Keyframes for slider animation */
@keyframes slider {
  0% {
    transform: translateX(0);
  }
  20% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-100%);
  }
  45% {
    transform: translateX(-100%);
  }
  50% {
    transform: translateX(-100%);
  }
  70% {
    transform: translateX(-100%);
  }
  75% {
    transform: translateX(-200%);
  }
  95% {
    transform: translateX(-200%);
  }
  100% {
    transform: translateX(-200%);
  }




}

@media screen and (max-width:900px){
  .test-con h1{
    font-size: 30px;
  }
}

/* kk */