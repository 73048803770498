/* General styles */
/* General Container Styles */
.purchase-container {
  text-align: center;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}


.purchase-cards {
  display: flex;
  gap: 2rem;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 1.5rem;
}

/* Card Styling */
.purchase-card {
  background: #ffffff;
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  width: 100%;
  max-width: 300px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.purchase-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.plan-name {
  font-size: 1.25rem;
  color: #333;
  margin-bottom: 0.5rem;
}

.plan-price {
  font-size: 1.5rem;
  font-weight: bold;
  color: grey;
  margin-bottom: 1rem;
}

.plan-description {
  font-size: 1rem;
  color: #666;
  margin-bottom: 1rem;
}

.plan-features {
  list-style-type: none;
  padding: 0;
  margin: 1rem 0;
}

.plan-features li {
  font-size: 0.9rem;
  color: #444;
  margin-bottom: 0.5rem;
}

/* Button Styling */



/* Highlighted Card */
.highlight {
  /* background: #9da7f4; */
  /* border: 2px solid #ff9800; */
}

/* Responsive Design */
@media screen and (max-width: 768px) {
  .purchase-cards {
    flex-direction: column;
    align-items: center;
  }
}
/* jjj */