/* Base Styles */
body {
  margin: 0;
 font-family: "Poppins", serif;
  line-height: 1.6;
}

.llm-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

/* Hero Section */
.llm-hero {
  /* background: #007aff; */
  color: white;
  text-align: center;
  padding: 50px 20px;
}

.llm-hero h1 {
  /* font-size: 2.5rem; */
  margin-bottom: 10px;
}



/* About Section */
.llm-about {
  margin: 30px 0;
  font-size: 1.1rem;
  text-align: center;
}

/* Features Section */
.llm-features {
  margin: 40px 0;
}

.llm-features h2 {
  text-align: center;
  margin-bottom: 20px;
  color: black;
  font-family: "Poppins", serif;
  font-weight: 600

}

.features-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
}

.feature-item {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  background: #f9f9f9;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.feature-item h3 {
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 10px;
}
.feature-item p{
    font-size: 18px;
}
/* Why Us Section */
.llm-why-us {
  margin: 40px 0;
}

.llm-why-us h2 {
  text-align: center;
  margin-bottom: 20px;
}

.llm-why-us ul {
  list-style-type: none;
  padding: 0;
}

.llm-why-us li {
  margin: 10px 0;
}

/* Call to Action Section */
.llm-cta {
  margin: 40px 0;
  text-align: center;
}

.llm-cta h2 {
  margin-bottom: 10px;
}



/* Responsive Design */
@media (max-width: 768px) {
 

  .llm-hero p {
    font-size: 1rem;
  }

  .features-grid {
    grid-template-columns: 1fr;
  }
}
