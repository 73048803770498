/* Chatbot Wrapper */
.chatbot-wrapper {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
}

/* Chatbot Container */
.chatbot-container {
  width: 400px; /* Fixed width */
  height: 600px; /* Fixed height */
  background: #ffffff;
  border-radius: 12px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  overflow: hidden; /* Prevent content overflow */
}

/* Chatbot Header */
.chatbot-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #0078d7;
  color: #fff;
  padding: 12px 16px;
}

.chatbot-title {
  font-size: 18px;
  font-weight: bold;
}

/* Icon Buttons */
.icon-btn {
  border: none;
  color: #fff;
  width: 50px;
  height: 50px;
  font-size: 18px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: color 0.3s ease;
}



/* Chatbot Content (Messages) */
.chatbot-content {
  flex-grow: 1;
  padding: 16px;
  overflow-y: auto; /* Enable vertical scrolling */
  background-color: #f9f9f9;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

/* Scrollbar Styling */
.chatbot-content::-webkit-scrollbar {
  width: 6px;
}

.chatbot-content::-webkit-scrollbar-thumb {
  background-color: #0078d7;
  border-radius: 3px;
}

.chatbot-content::-webkit-scrollbar-thumb:hover {
  background-color: #005bb5;
}

/* Chat Messages */
.chat-message {
  margin-bottom: 10px;
  padding: 10px 14px;
  border-radius: 16px;
  max-width: 80%;
  line-height: 1.4;
}

.bot-message {
  background-color: #0078d7;
  color: #fff;
  align-self: flex-start;
}

.user-message {
  background-color: #e0e0e0;
  color: #333;
  align-self: flex-end;
}

/* Chatbot Input Section */
.chatbot-input {
  display: flex;
  gap: 8px;
  padding: 16px;
  background-color: #ffffff;
}

.chatbot-textbox {
  flex-grow: 1;
  padding: 10px;
  border-radius: 6px;
  border: 1px solid #e0e0e0;
  font-size: 14px;
}

.send-btn {
  color: #0078d7;
  font-size: 20px;
  padding: 8px;
  width: 50px;
  height: 50px;
  background: none;
  border: none;
  cursor: pointer;
  transition: color 0.3s ease;
}



/* Chatbot Toggle Button */
.chatbot-toggle-btn {
  background-color: #0078d7;
  color: white;
  padding: 12px 18px;
  border: none;
  border-radius: 50px;
  font-size: 16px;
  cursor: pointer;
  transition: background 0.3s ease;
}

.chatbot-toggle-btn:hover {
  background-color: #005bb5;
}
