.api-copy-container {
  /* width: 90%; */
  border-radius: 5px;
  margin: auto;
}

.api-info {
  margin-bottom: 10px;
}

.api-copy {
  padding: 5px;
  border-radius: 0px;
  font-size: 10px;
  font-family: monospace;
  margin-top: 10px;
  margin-bottom: 0px;
  color: white;
}

.api-example {
  background-color: black;
  /* background-color: #f4f4f4; */
  padding: 20px;
  border-radius: 0px;
  font-size: 16px;
  font-family: monospace;
  margin-top: 0px;
  overflow: scroll;
}
