/* SearchJobs.css */
.search-jobs {
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin: 20px auto;
    max-width: 85%;
}

.search-header {
  text-align: left;
}

.search-results-info {
    margin-bottom: 20px;
}

.pagination {
  display: flex;
  justify-content: space-between;
}

.pagination button {
    margin: 0 5px;
    padding: 8px 16px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.pagination button:hover {
    background-color: #0056b3;
}
