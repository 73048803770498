/* General Container Styles */
.plan-container {
  padding: 20px;
  text-align: center;
  background-color: #f8f8f8;
}
.title{

}
/* Title Styles */
.header2 {
  font-size: 1.5rem;
  color: #007BFF;
  margin-bottom: 10px;
}

.plan-title {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 30px;
  color: #333;
}

/* Plan Cards Container */
.plan-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  margin: 20px 0;
}

/* Individual Plan Card Styles */
.plan-card {
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  max-width: 300px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.plan-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

/* Plan Name */
.plan-name {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 15px;
  color: #333;
}

/* Plan Price Section */
.plan-price {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 15px;
  color: #007BFF;
}

.plan-price p {
  margin: 0;
  
}

.mon {
  font-size: 1rem;
  color: grey;
  margin-left: 5px;
}

/* Plan Description */
.plan-description {
  font-size: 18px;
  color: #666;
  margin-bottom: 20px;
  line-height: 1.6;
  font-family: "Poppins", serif;
}

/* Button */
.learn-m {
  padding: 10px 20px;
  font-size: 1rem;
  background-color: #007BFF;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.learn-m:hover {
  background-color: #0056b3;
}

/* Responsive Design */
@media screen and (max-width: 900px) {
  .plan-cards {
    gap: 15px;
  }

  .plan-card {
    max-width: 100%;
  }

  .plan-title {
    font-size: 2rem;
  }

  .header2 {
    font-size: 1.25rem;
  }
}

@media screen and (max-width: 600px) {
  .plan-title {
    font-size: 1.8rem;
  }

  .plan-price {
    flex-direction: column;
    font-size: 1.8rem;
  }

  .plan-price p {
    font-size: 1rem;
    font-family: "Poppins", serif;
  }

  .header2 {
    font-size: 1rem;
  }
}

/* jjj */