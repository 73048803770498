.rolling-images-container {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  max-height: 100%; /* Ensure the parent div takes up the full height of its container */
  margin: auto;
}

.rolling-images {
  display: flex;
}

.image {
  flex: 0 0 auto;
  margin-right: 10px;
  margin-bottom: 10px;
  opacity: 0.5;
  border-radius: 8px;
}

@keyframes roll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-100% + 10px));
  }
}
