.setup-container {
  padding: 20px;
  background-color: #f7f7f7;
  border-radius: 10px;
}

.setup-container h2 {
  color: #333;
}

.setup-description {
  margin-bottom: 20px;
}

.setup-instructions {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
}

.setup-instructions h3 {
  margin-top: 0;
}
