.fade-in-view.is-visible {
  opacity: 1;
  padding: 0px;
  margin: 0px;
  overflow: hidden;
}

.fade-in-view {
  /* Remove padding and margin */
  padding: 0px;
  margin: 0px;
  overflow: hidden;

  /* Other styles */
}
