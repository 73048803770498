.profile-container {
  max-width: 400px;
  margin: auto;
  margin-top: 10px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

h2 {
  text-align: center;
}

.profile-form {
  display: flex;
  flex-direction: column;
}

label {
  margin-bottom: 5px;
}

input[type="text"],
textarea,
select {
  width: 100%;
  margin-bottom: 10px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 3px;
}

.resume-link {
  margin: 10px;
}

.resume-link a {
  text-decoration: none;
  color: #007bff;
}

.resume-link a:hover {
  text-decoration: underline;
}



.submit-button {
  margin: 3px;
}

.cancel-button {
  background-color: red;
  margin: 3px;
}
