.title-container {
  text-align: center;
}

.fall-in {
  animation: fall-in 1s ease-out;
}

@keyframes fall-in {
  from {
    opacity: 0;
    transform: translateY(-50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.fall-in:hover {
  transform: scale(1.1);
}
