/* Base styles */
body {
  margin: 0;
  /* font-family: 'Arial', sans-serif; */
  line-height: 1.6;
}

.infrastructure-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  font-family: "Poppins", serif;
}

/* Hero Section */
.hero-section {
  /* background: #0073e6; */
  color: white;
  padding: 50px 20px;
  text-align: center;
}

.hero-section h1 {

  margin-bottom: 10px;
}

.hero-section p {
  font-size: 1.2rem;
  font-family: "Poppins", serif;
}

/* About Section */
.about-section {
  display: flex;
  justify-content: center;  /* Horizontally center the content */
  align-items: center;      /* Vertically center the content */
  text-align: center;       /* Center the text inside the section */
  padding: 20px;          /* Add padding for spacing */
  background-color: #f9f9f9;/* Optional: background color for contrast */
  font-size: 30px;       /* Set the font size */
  color: #333;              /* Text color */
  font-family: Arial, sans-serif; /* Set a readable font */
}

/* Features Section */
.features-section {
  margin: 40px 0;
}

.features-section h2 {
  text-align: center;
  margin-bottom: 20px;
  font-weight: 600;
  font-family: "Poppins", serif;
  color: black;
}

.features-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
}

.feature-item {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  background: #f9f9f9;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.feature-item h3 {
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 10px;
}

/* Why Us Section */
.why-us-section {
  margin: 40px 0;
}

.why-us-section h2 {
  text-align: center;
  margin-bottom: 20px;
}

.why-us-section ul {
  list-style-type: none;
  padding: 0;
}

.why-us-section li {
  margin: 10px 0;
}

/* Call to Action Section */
.cta-section {
  margin: 40px 0;
  text-align: center;
}

.cta-section h2 {
  margin-bottom: 10px;
}

.cta-section p {
  font-size: 1rem;
  line-height: 1.5;
}

/* Responsive Design */
@media (max-width: 768px) {
  .hero-section h1 {
    font-size: 2rem;
  }



  .features-grid {
    grid-template-columns: 1fr;
  }
}
