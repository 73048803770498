/* General Styles */
body {
  font-family: "Poppins", sans-serif;
  margin: 0;
  padding: 0;
  color: #333;
  background-color: #f4f4f9;
  line-height: 1.6;
}

/* Methodology Container */
.methodology-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 60px 20px;
  background: linear-gradient(135deg, #007BFF, #0056b3);
  color: #fff;
  text-align: center;
  border-radius: 10px;
  flex-wrap: wrap;
}

/* Section Headers */
.header2 {
  margin-top: 40px;
  color: #007BFF;
  font-size: 2.5rem;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.automation-solution {
  margin: 40px auto;
  width: 98%;
  padding: 50px;
  text-align: center;
}

.automation-solution h1 {
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: #222;
}

.automation-solution p {
  font-size: 1.1rem;
  color: #555;
}

/* Features Section */
.features {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  padding: 40px 20px;
}

/* Individual Feature Box */
.feature {
  width: 300px;
  padding: 30px;
  background: #fff;
  border-radius: 15px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  text-align: center;
  position: relative;
  overflow: hidden;
}

/* Subtle Hover Effect */
.feature:hover {
  transform: translateY(-8px);
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2);
}

/* Title Styling */
.feature h2 {
  font-size: 1.6rem;
  font-weight: bold;
  margin-bottom: 12px;
  color: #007BFF;
  text-transform: uppercase;
  letter-spacing: 1px;
}

/* Description Styling */
.feature p {
  font-size: 1.1rem;
  color: #555;
  margin-bottom: 20px;
  line-height: 1.5;
}

/* Feature Border Animation */
.feature::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 5px;
  background: #007BFF;
  left: 0;
  bottom: 0;
  transition: all 0.3s ease-in-out;
}

.feature:hover::before {
  height: 8px;
  background: #0056b3;
}


/* Buttons */
.learn-more-button,
.methodology-button {
  display: inline-block;
  margin-top: 20px;
  padding: 12px 24px;
  font-size: 1rem;
  font-weight: 600;
  text-transform: uppercase;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  box-shadow: 0 3px 10px rgba(0, 123, 255, 0.2);
}

.learn-more-button:hover {
  background-color: #0056b3;
  transform: scale(1.05);
  box-shadow: 0 5px 15px rgba(0, 86, 179, 0.3);
}

.methodology-button {
  background-color: #28a745;
  width: 60%;
}

.methodology-button:hover {
  background-color: #218838;
  transform: scale(1.08);
  box-shadow: 0 6px 15px rgba(40, 167, 69, 0.3);
}

/* Feature Hover Effects */
.one:hover,
.two:hover,
.three:hover,
.four:hover {
  background: #e9ecef;
  transition: background-color 0.3s ease;
}

@media (max-width:480px) {
  .features{
  flex-wrap: wrap;
  padding-inline:10px ;
  }
      
}

