/* ApiFunction.css */
.api-function {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 20px;
  margin-bottom: 0px;
}

.api-info {
  margin-bottom: 10px;
}

.api-description {
  font-style: italic;
  margin-bottom: 10px;
}

.api-copy-container {
  /* width: 90%; */
  border-radius: 5px;
  margin: auto;
}

.api-copy {
  background-color: #404040;
  padding: 5px;
  border-radius: 0px;
  font-size: 10px;
  font-family: monospace;
  margin-top: 10px;
  margin-bottom: 0px;
}

.api-example {
  background-color: #f4f4f4;
  padding: 20px;
  border-radius: 0px;
  font-size: 16px;
  font-family: monospace;
  margin-top: 0px;
}
