.job-item {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.company-icon {
  width: 60px; /* Adjust size as needed */
  height: 60px; /* Adjust size as needed */
  margin-right: 20px;
}

.job-details {
  display: flex;
  flex-direction: column;
  flex: 1;
  font-family: "Poppins", serif;
}

.job-title {
  font-weight: bold;
  margin-bottom: 5px;
  font-family: "Poppins", serif;
}

.location {
  color: blue;
}

.salary {
  color: green;
}

.company,
.location,
.salary,
.posted-date {
  margin-bottom: 5px;
}

.posted-date {
  align-self: flex-end;
}

/* Add additional styling as needed */
