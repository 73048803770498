/* Container Styles */
.login-container {
  display: flex;
  height: 90vh; /* Full viewport height */
  width: 100%;
  font-family: 'Arial', sans-serif;
  background: linear-gradient(to right, #d3dde6, #f8f9fa); /* Subtle gradient background */
}

/* Left Image Section (2/3 of the screen) */
.image-half {
  flex: 2; /* Occupies 2/3 of the screen */
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(135deg, #7d8da1, #a0b4c8); /* Sleek gradient background */
  overflow: hidden; /* Prevent overflow */
}

.login-image {
  max-width: 80%;
  max-height: 80%;
  object-fit: cover;
  border-radius: 20px; /* Rounded corners */
  box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.3); /* Add shadow for depth */
  animation: fadeIn 1.2s ease-in-out; /* Smooth fade-in animation */
}

/* Right Form Section (1/3 of the screen) */
.form-half {
  flex: 1; /* Occupies 1/3 of the screen */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px;
  background: #f1f5f9; /* Subtle light background */
  box-shadow: -10px 0px 20px rgba(0, 0, 0, 0.1); /* Separation shadow */
}

/* Form Content */
.form-content {
  width: 100%;
  max-width: 400px;
  padding: 25px;
  text-align: center;
  background: white;
  border-radius: 15px; /* Rounded corners */
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1); /* Light shadow for depth */
  display: flex;
  flex-direction: column;
  justify-content: center;
}

h2 {
  margin-bottom: 20px;
  font-size: 30px;
  color: #2c3e50;
  font-weight: bold;
  text-align: center;
}

/* Form Styles */
form {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px; /* Add spacing between inputs */
}

/* Input Field Styles */
input {
  padding: 12px 15px;
  font-size: 16px;
  border: 2px solid #ddd;
  border-radius: 20px !important; /* Softer rounded corners */
  outline: none;
  background: #f9f9f9; /* Light background for input */
  transition: all 0.3s ease-in-out;
  box-shadow: inset 0px 3px 6px rgba(0, 0, 0, 0.1); /* Inner shadow for depth */
}

input:focus {
  border-color: #007bff; /* Blue border on focus */
  background: #fff; /* White background on focus */
  box-shadow: 0px 4px 10px rgba(0, 123, 255, 0.2); /* Subtle outer glow */
}

input::placeholder {
  color: #aaa; /* Subtle placeholder color */
  font-style: italic;
}



/* Error Message */
.error-message {
  color: #e74c3c;
  margin-bottom: 15px;
  font-size: 14px;
  animation: shake 0.3s ease-in-out; /* Shake animation for error feedback */
}

/* Additional Options */
.additional-options {
  margin-top: 15px;
  font-size: 14px;
  text-align: center;
}

.alt-link {
  color: #007bff;
  text-decoration: none;
  margin: 0 10px;
  transition: color 0.3s;
}

.alt-link:hover {
  color: #0056b3;
  text-decoration: underline;
}

/* Animations */
@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes shake {
  0%, 100% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-5px);
  }
  50% {
    transform: translateX(5px);
  }
  75% {
    transform: translateX(-5px);
  }
}

/* Responsive Design */
@media (max-width: 768px) {
  .login-container {
    flex-direction: column;
    height: auto; /* Adjust height for stacked layout */
  }

  .image-half {
    flex: 1;
    height: 50vh;
    justify-content: center;
    align-items: center;
    background: linear-gradient(135deg, #7d8da1, #a0b4c8); /* Retained gradient background */
  }

  .form-half {
    flex: 1;
    width: 100%;
    padding: 20px;
    box-shadow: none; /* Remove shadow for stacked layout */
  }

  .login-image {
    max-width: 70%;
    max-height: 70%;
  }
}

@media (max-width: 480px) {
  h2 {
    font-size: 24px;
  }

  input, button {
    font-size: 14px;
    padding: 10px;
  }

  .alt-link {
    font-size: 12px;
  }

  .additional-options {
    margin-top: 10px;
  }
}
