/* Footer Container */
.footer {
  background-color: #333;
  color: #fff;
  padding: 20px 15px;
  /* margin-top: 100px; */
  font-family:  "Poppins", serif;
}

.footer-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 6rem;
}

.footer-section {
  flex: 1;
  min-width: 200px;
}

.footer-section h4 {
  margin-bottom: 10px;
  font-size: 18px;
  color: #f4f4f4;
}

.footer-section p {
  font-size: 14px;
  line-height: 1.6;
}

.footer-links {
  list-style: none;
  padding: 0;
}

.footer-links li {
  margin-bottom: 10px;
}

.footer-links li a {
  color: #fff;
  text-decoration: none;
  transition: color 0.3s;
}

.footer-links li a:hover {
  color: #00bcd4;
}

/* Social Media Icons */
.social-icons {
  display: flex;
  gap: 15px;
}

.social-icons a {
  color: #fff;
  font-size: 18px;
  text-decoration: none;
  transition: color 0.3s;
}

.social-icons a:hover {
  color: #00bcd4;
}

/* Footer Bottom */
.footer-bottom {
  margin-top: 20px;
  text-align: center;
  font-size: 14px;
  color: #bbb;
  border-top: 1px solid #444;
  padding-top: 10px;
}

/* Responsive Design */
@media (max-width: 768px) {
  .footer-container {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .social-icons {
    justify-content: center;
  }
}
