.introduction_div {
  /* height: 100vh; */
  height: fit-content;


  width: 100%;



}

.introduction_container {
  padding: 2%;
  text-align: center;
  padding-bottom: 40px;
  /* opacity: 0.8; */

  /* color: rgba(0, 0, 0, 1); */
  color: rgba(255, 255, 255);
  background-color: rgba(0, 0, 0, 0.8);
  /* background-color: rgb(0, 0, 0); */
  height: fit-content;
}


.introduction_container h1 {
  font-size: 60px;
  text-align: center;
  font-family: "Poppins", serif;
}

.introduction_text {
  font-size: 30px;
  font-family: "Poppins", serif;
  text-align: center;
  margin: 5px;
}

.introduction-search-button {
  margin: 0px;
  /* width: 40%; */
  height: 50px;
  border-radius:10px ;
  width: 300px;
  text-transform: uppercase;
  font-family: "Poppins", serif;

  background-color: green;
}

@keyframes scaleAnimation {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.2); /* Set the desired scale factor */
  }
}

.introduction-search-button:hover {
  animation: scaleAnimation 0.1s ease forwards;
}

.cloud-div {
  max-width: 60%; /* Bounded within a smaller width */
  padding-top: 50px;
  margin: 0 auto; /* Center the text horizontally */
}

.cloud-text {
  font-size: 24px; /* Larger font size */
  text-align: center; /* Center the text */
  color: #fff; /* Adjust the color as needed */
  font-family: "Poppins", serif;
}

.cloud-logos {
  display: flex;
  justify-content: space-around; /* Spread out the images */
  align-items: center; /* Center the images vertically */
  margin-top: 10px; /* Adjust the margin as needed */
}

.cloud-logo {
  width: auto; /* Make the cloud images larger */
  height: 120px; /* Maintain aspect ratio */
  margin: 0 10px; /* Adjust the margin between logos as needed */
  border-radius: 10px;
}

.introduction_h1 {
  margin-top: 40px;
  margin-bottom: 0px;
  padding: 0px;
  color: white;
  font-family: "Poppins", serif;
  text-align: center; /* Centers the content (image) */
}

.introduction_h1 img {
  width: 150px; /* Set a fixed width to control the image size */
  height: auto; /* Keeps the aspect ratio intact */
  border-radius: 10%; /* Makes the image rounded (adjust as needed for perfect circle) */
  border: 3px solid #cce6ff; /* Optional: Add a white border around the image */
  padding: 5px; /* Optional: Adds spacing between the image and border */
  display: inline-block; /* Prevents image from taking up full width */
}


.rres{
  display: none;
}

@media screen and (max-width:900px) {

  .introduction_container h1{
    font-size: 7vw;
  }
  .cloud-logos{

    display: none;
  }

  .rres {
    display: flex;


  }
  .cloud-text{
    font-size: 18px;
  }
}

/* kjlkjkjj;jl */
