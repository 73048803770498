


 /* General Styles */
body {
  margin: 0;
  padding: 0;
  font-family: "Arial", sans-serif;
  background-color: #f5f5f5;
}

/* User Info Layout */
.user-info {
  display: flex;
  min-height: 100vh;
  background-color: #f5f5f5;
}

.sidebar {
  width: 250px;
  background-color:#007BFF;
  color: white;
  padding: 20px;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
}

.sidebar h2 {
  margin: 0 0 20px;
  font-size: 1.5rem;
}

.menu {
  list-style: none;
  padding: 0;
}

.menu-item {
  padding: 10px 0;
  cursor: pointer;
  color: white;
}

.menu-item.active {
  font-weight: bold;
}

.logout {
  margin-top: 20px;
  cursor: pointer;
  color: #ffe0b2;
  text-decoration: underline;
}

/* Main Content */
.info-content {
  flex: 1;
  padding: 30px;
  background-color: white;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
}

.info-header {
  text-align: center;
  margin-bottom: 20px;
}

.info-picture {
  position: relative;
  margin: 0 auto;
}

.info-avatar {
  margin-inline:auto ;
  width: 130px;
  height: 130px;
  border-radius: 50%;
  border: 3px solid #007BFF;
}

.info-details {
  background: #fafafa;
  padding: 20px;
  border-radius: 8px;
  font-size: 1rem;
  color: #333;
}

.info-details p {
  margin: 15px 0;
  line-height: 1.5;
}

.info-details strong {
  color: #000;
}

.info-details a {
  color: #ff5722;
  text-decoration: none;
  font-weight: bold;
}

.info-details a:hover {
  text-decoration: underline;
}

/* Responsive Design */
@media (max-width: 768px) {
  .sidebar {
    width: 200px;
    padding: 15px;
  }

  .info-avatar {
    width: 100px;
    height: 100px;
  }

  .info-content {
    padding: 20px;
  }

  .info-details {
    font-size: 0.95rem;
  }
}

@media (max-width: 480px) {
  .sidebar {
    display: none;
  }

  .info-content {
    padding: 15px;
  }

  .info-avatar {
    width: 80px;
    height: 80px;
  }

  .info-details {
    font-size: 0.85rem;
  }
}

/* hatchpath */