


.header {
  
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 30px;
  background-color:#333;
  /* border-bottom: 1px solid #ddd; */
  /* gap: 3rem; */
  position: sticky;
  width: 100%;
  top: 0;
  z-index: 1000;
}

.logo-container {
  display: flex;
  align-items: center;
}
.left {
  display: flex;
  gap: 3rem;
}
.logo {
  width: 60px;
  height: auto;
  margin-right: 10px;
  /* transition: all 500ms 0s linear;

  &:hover{
    scale: 1.2;
  } */
}
.fle{
  display: flex;
  gap: 2rem;
}

/* Contact Us Button */
.contact-desktop {
  margin-right: 15px;
  padding: 10px 20px;
  border-radius: 8px;
  background-color: #000000;
  color: #fff;
  text-decoration: none;
  font-weight: 500;
  transition: background-color 0.3s ease;
}


.link-container {
  display: flex;
  /* border: 2px solid red; */
 
  justify-content: space-between;
  width: 87vw;

  align-items: center;
}

.nav-link {
  text-decoration: none;
  color: white;
  font-size: 16px;
  font-weight: 500;
  transition: color 0.3s;
  cursor: pointer;
}

/* .nav-link:hover {
  color: #007bff;
} */

/* Hamburger menu icon */
.hamburger-menu {
  display: none;
  background: none;
  border: none;
  cursor: pointer;
  position: relative;
  width: 30px;
  height: 25px;
}

.dropdown-menu{
  position: absolute;
   background-color: #1e1e1e; 
  border-radius: 12px; 
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.2); 
  overflow: hidden;
  z-index: 1000;
  min-width: 200px; 
  animation: fadeIn 0.3s ease-in-out; 
  
}

.dropdown-item {
  padding: 12px 20px; 
  color: #fff;
  text-decoration: none;
  display: flex; 
  align-items: center;
  font-size: 0.95rem; 
  font-weight: 500; 
  transition: background-color 0.3s, transform 0.2s;
}

.hamburger-icon,
.hamburger-icon::before,
.hamburger-icon::after {
  display: block;
  width: 30px;
  height: 3px;
  background-color: white;
  border-radius: 2px;
  position: absolute;
  transition: transform 0.3s, opacity 0.3s;
}

.hamburger-icon::before {
  content: "";
  top: -8px;
}

.hamburger-icon::after {
  content: "";
  bottom: -8px;
}

.hamburger-menu.active .hamburger-icon {
  background-color: transparent;
}

.hamburger-menu.active .hamburger-icon::before {
  transform: translateY(8px) rotate(45deg);
}

.hamburger-menu.active .hamburger-icon::after {
  transform: translateY(-8px) rotate(-45deg);
}

/* Show active menu for mobile */
.link-container.active {
  display: flex;
  flex-direction: column;
  position: absolute;
  align-items: center;
  top: 60px;
  left: 0;
  width: 100%;
  background-color:#333 ;
  padding: 10px 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 999;
}

.link-container.active .nav-link {
  padding: 10px 0;
  /* color: black; */
  border-bottom: 1px solid #ddd;
}

.link-container.active .nav-link:last-child {
  border-bottom: none;
}

/* Responsive styles */
@media (max-width: 768px) {
  .link-container {
    display: none;
    flex-direction: column;
    width: inherit;
    gap: 2rem;

  }

  .fle{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .dropdown-menu{
    position: initial;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: none;
    box-shadow: none;
  }

  .hamburger-menu {
    display: block;
  }
}
