body{
  overflow-x: hidden;
}

.hero-title {
  font-size: 50px;
  font-weight: bold;
  color: #999999;
  margin-bottom: 30px;
  line-height: 1.4;
}

/* Subtitle */
.hero-subtitle {
  font-size: 40px;
  font-weight: 400;
  color: #fff;
  margin-bottom: 30px;
  line-height: 1.4;
}

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');




  .quick-guide{
    text-align: center;
     font-family: "Poppins", serif;
     background-color:#ffffff;
     padding-top: 5px;

  }

  .shell-api{
    color: #007bff;
  }


  .system-con b{
    margin-bottom: 30px;
  }

  .quick-guide .system-con{
    background-color:#ffffff;
     font-family: "Poppins", serif;
  }


  .video-h1{
    text-align: center;
     font-family: "Poppins", serif;
  }

  .quick-guide .system-con{
     margin-block: 0px;
  }


  .intro-text{
     font-family: "Poppins", serif;
     padding-inline: 200px;
     font-size: 18px;
     text-align: center;
     margin-bottom: 20px;

  }

  @media (max-width: 768px) {
    .info-container {
        display: flex;
      flex-direction: column;
      /* border: 2px solid red; */
      /* flex-wrap: wrap; */
      gap: 1rem;
      justify-content: space-between;
    }

    .info-box {
      flex: 0 1 calc(33% - 1rem);
      width: 100%;
      margin: 0.5rem;
    }

    .intro-text{
      padding-inline:20px;
    }

}
