.job-list-container {
  display: flex;
  flex-direction: column;
  margin: 10px;
  font-family: "Poppins", serif;
  /* align-items: center; */
}

.job-item-link {
  text-decoration: none;
  color: inherit;
  margin: 0px;

  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.job-item-link:hover {
  background-color: #ccccff;
}
