/* FeatureComparisonTable.css */

.table-container {
  margin-top: 20px;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.1);
}

.title {
  color: #007BFF;
  text-align: center;
  font-size: 26px;
  margin-bottom: 20px;
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
}

table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  background-color: white;

  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.1);
}

thead {
  background: linear-gradient(135deg, #2193b0, #2193b0);
  color: #fff;
}

th {
  font-size: 16px;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  padding: 16px;
  text-align: center;
  border-bottom: 2px solid #eaeaea;
}

.tool-header {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
}

.company-logo {
  position: relative;
  width: 35px;
  height: 35px;
  overflow: hidden; /* Ensures the overlay stays within the logo boundaries */
  filter: brightness(0) invert(1); /* Makes the logo white */
}

.company-logo::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(33, 147, 176, 0.5); /* Adjust overlay color and transparency */
  pointer-events: none; /* Prevents the overlay from blocking interactions */
}


.tool-name {
  font-size: 14px;
  font-weight: bold;
  color: #fff;
}

.highlight-column {
  /* background: linear-gradient(135deg, #2193b0, #2193b0); */
  color: #fff;
  font-weight: bold;
}

tbody tr {
  border-bottom: 1px solid #e5e5e5;
  transition: background-color 0.3s ease;
}

td {
  text-align: center;
  vertical-align: middle;
  background-color: white; /* Ensure white background */
  font-size: 14px;
  padding: 10px; /* Reduce padding */
  height: 50px; /* Set a fixed height */
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  color: #444; /* Darker text for readability */
}

/* Ensure icons are properly centered */
td svg {
  display: block;
}


td {
  font-size: 14px;
  padding: 14px;
  margin: auto;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  color: #444;
  border: 1px solid #ddd;
  text-align: center;
}

/* Feature Icon - Centralized */
.icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  border: 2px solid #FFD700; /* Gold/Yellow Border */
  border-radius: 50%;
  padding: 5px;
  box-sizing: border-box; /* Ensures padding doesn't affect centering */
  transition: background-color 0.3s ease, transform 0.2s ease-in-out;
}

.feature-icon {
  width: 22px;
  height: 22px;
  object-fit: contain;
  filter: brightness(0) saturate(100%) invert(73%) sepia(87%) saturate(500%) hue-rotate(5deg) brightness(105%) contrast(110%);
  transition: filter 0.3s ease-in-out;
  margin: 0; /* Remove unnecessary margins */
}

.checkmarks-container {
  display: flex; /* Makes the checkmarks appear side by side */
  justify-content: center;
}
